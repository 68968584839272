export default[
    {
      key: "url_image",
      label: "Image",
      class: "text-left",
      sortable: false,
    },
    {
      key: "code",
      label: "Code",
      class: "text-left",
      sortable: false,
    },
    {
      key: "name_category",
      label: "Category",
      class: "text-left ",
      sortable: false,
    },
    {
      key: "product",
      label: "Product",
      class: "text-left",
      sortable: false,
    },
    {
      key: "description",
      label: "Description",
      class: "text-left",
      sortable: false,
    },
    {
      key: "currency",
      label: "Currency",
      class: "text-left",
      sortable: false,
    },
    {
      key: "cost",
      label: "Cost",
      class: "text-left",
      sortable: false,
    },
    {
        key: "current_stock",
        label: "Stock",
        class: "text-left",
        sortable: false,
    },
    {
      key: "tracking",
      label: "Tracking",
      class: "text-left",
      sortable: false,
    },
    {
      key: "created_at",
      label: "Created By",
      class: "text-left",
      sortable: true,
    },
    {
      key: "actions",
      label: "Actions",
      class: "text-left",
      sortable: false,
    }
]