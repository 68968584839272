var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"title":_vm.productId ? 'EDIT PRODUCT' : 'REGISTER NEW PRODUCT',"ok-title":'SAVE',"size":"lg"},on:{"hidden":_vm.closeModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.saveProduct}},[_vm._v("SAVE")])]},proxy:true}]),model:{value:(_vm.onControl),callback:function ($$v) {_vm.onControl=$$v},expression:"onControl"}},[_c('ValidationObserver',{ref:"form"},[_c('div',[_c('div',{staticClass:"mt-3"},[_c('b-row',[_c('b-col',{attrs:{"sm":"8"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"CODE:","label-for":"input-2"}},[_c('b-form-input',{staticClass:"input-background-white",class:{ 'border border-danger': errors[0] },attrs:{"placeholder":"Code","disabled":_vm.productId ? true : false},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1)]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('feather-icon',{staticClass:"cursor-pointer",staticStyle:{"float":"right"},attrs:{"icon":"PlusIcon","size":"20","title":"NEW CATEGORY"},on:{"click":function($event){return _vm.openModalAddCategory()}}}),_c('b-form-group',{attrs:{"id":"input-group-3","label":"CATEGORY:","label-for":"input-3"}},[_c('b-form-select',{staticClass:"select-icon-none",class:{ 'border border-danger': errors[0] },attrs:{"options":_vm.optionsCategory,"value-field":"id","text-field":"name"},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Please select an option")])]},proxy:true}],null,true),model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})],1)]}}])})],1),_c('b-col',{attrs:{"sm":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"PRODUCT:","label-for":"input-2"}},[_c('b-form-input',{staticClass:"input-background-white",class:{ 'border border-danger': errors[0] },attrs:{"placeholder":"Product"},model:{value:(_vm.nameProduct),callback:function ($$v) {_vm.nameProduct=$$v},expression:"nameProduct"}})],1)]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Currency:","label-for":"input-2"}},[_c('div',[_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center","margin-top":"10px"}},[_c('b-form-radio',{staticClass:"mr-3",attrs:{"aria-describedby":ariaDescribedby,"name":"some-radios","value":"1"},model:{value:(_vm.currency),callback:function ($$v) {_vm.currency=$$v},expression:"currency"}},[_vm._v("$")]),_c('b-form-radio',{attrs:{"aria-describedby":ariaDescribedby,"name":"some-radios","value":"2"},model:{value:(_vm.currency),callback:function ($$v) {_vm.currency=$$v},expression:"currency"}},[_vm._v("S/")])],1)]}}])})],1)])],1),_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"COST:","label-for":"input-2"}},[_c('money',_vm._b({staticClass:"form-control",class:{
                          'border border-danger': errors[0] && _vm.vmoneyValidate,
                        },model:{value:(_vm.cost),callback:function ($$v) {_vm.cost=$$v},expression:"cost"}},'money',{
                          decimal: '.',
                          thousands: ',',
                          prefix: _vm.currency == 1 ? '$ ' : 'S/ ',
                          precision: 2,
                          masked: false,
                        },false))],1)]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"STOCK:","label-for":"input-2"}},[_c('b-form-input',{staticClass:"input-background-white",class:{ 'border border-danger': errors[0] },attrs:{"type":"number","placeholder":"Stock"},model:{value:(_vm.stock),callback:function ($$v) {_vm.stock=$$v},expression:"stock"}})],1)]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"id":"input-group-3","label":"PURCHASE DATE:","label-for":"input-3"}},[_c('b-form-datepicker',{staticClass:"mb-2",class:{ 'border border-danger': errors[0] },model:{value:(_vm.dateRegister),callback:function ($$v) {_vm.dateRegister=$$v},expression:"dateRegister"}})],1)]}}])})],1),_c('b-col',{attrs:{"sm":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"DESCRIPTION:","label-for":"input-2"}},[_c('b-form-textarea',{class:{ 'border border-danger': errors[0] },attrs:{"placeholder":"Enter something...","rows":"3","max-rows":"6"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)]}}])})],1)],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('div',{staticClass:"form-group",staticStyle:{"border":"5px solid black","border-radius":"15px","padding":"10px"}},[_c('div',{staticClass:"image-upload"},[_c('input',{attrs:{"type":"file","id":"file_input_1","hidden":"","disabled":!_vm.viewEdit && _vm.productId ? true : false},on:{"change":_vm.getImage}}),_c('div',{staticClass:"form-group",style:(_vm.viewEdit || !_vm.productId
                        ? 'cursor: pointer'
                        : 'cursor: not-allowed')},[_c('figure',{on:{"click":function($event){return _vm.inputImage()}}},[_c('img',{attrs:{"width":"100%","height":"80%","src":_vm.image}})])])])]),(_vm.productId)?_c('div',{staticClass:"form-group",staticStyle:{"text-align":"center"}},[(!_vm.viewEdit)?_c('div',[_c('feather-icon',{staticClass:"cursor-pointer mr-1",staticStyle:{"color":"green"},attrs:{"icon":"EditIcon","size":"25"},on:{"click":function($event){return _vm.clickEdit(1)}}}),_c('feather-icon',{staticClass:"cursor-pointer mr-1",staticStyle:{"color":"red"},attrs:{"icon":"Trash2Icon","size":"25"},on:{"click":function($event){return _vm.saveImage(1)}}})],1):_c('div',[_c('feather-icon',{staticClass:"cursor-pointer mr-1",staticStyle:{"color":"blue"},attrs:{"icon":"SaveIcon","size":"25"},on:{"click":function($event){return _vm.saveImage(2)}}}),_c('feather-icon',{staticClass:"cursor-pointer mr-1",staticStyle:{"color":"red"},attrs:{"icon":"XCircleIcon","size":"25"},on:{"click":function($event){return _vm.clickEdit(2)}}})],1)]):_vm._e()])],1)],1)])])],1),_c('b-modal',{attrs:{"modal":"","title":"REGISTER CATEGORY","ok-title":"SAVE"},on:{"ok":function($event){$event.preventDefault();return _vm.registerCategory.apply(null, arguments)}},model:{value:(_vm.ModalAddCategory),callback:function ($$v) {_vm.ModalAddCategory=$$v},expression:"ModalAddCategory"}},[_c('div',[_c('ValidationObserver',{ref:"formCategory"},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"CATEGORY:","label-for":"input-2"}},[_c('b-form-input',{class:{ 'border border-danger': errors[0] },attrs:{"placeholder":"..."},model:{value:(_vm.newCategory),callback:function ($$v) {_vm.newCategory=$$v},expression:"newCategory"}})],1)]}}])})],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }