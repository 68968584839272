<template>
  <div>
    <b-modal
      modal
      title="Tracking"
      v-model="mutableIfModalProduct"
      size="lg"
      modal-class="modal-primary"
      hide-footer
      body-class="mb-2"
      @hidden="closeModal"
      title-tag="h3"
      scrollable
      fixed
    >
      <div class="table-responsive">
        <!-- Table -->
        <b-table
          :api-url="'/logistics/products-inventory/get-tracking-product'"
          ref="refTrackingProduct"
          class="position-relative"
          :items="myProvider"
          stacked="lg"
          :fields="arrayColumns"
          primary-key="id"
          table-class="text-nowrap table-seccion-head-gray"
          responsive
          show-empty
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1"></b-spinner>
              <strong>Loading ...</strong>
            </div>
          </template>
          <!-- Column STATUS -->
          <template #cell(status)="data">
            <p
              :style="statusColor(data.item.status)"
            >
              {{ data.item.status }} ( {{ data.item.cant }} )
            </p>
          </template>

          <!-- Column CREATED BY -->
          <template #cell(created_by)="data">
            {{ data.item.created_by }}
            <br />
            {{ data.item.created_at | myGlobalDay }}
          </template>

          <!-- Column COMMENTARY -->
          <template #cell(description)="data">
            <div
              style="white-space: normal"
              v-html="data.item.description"
            ></div>
          </template>
        </b-table>
      </div>
    </b-modal>
  </div>
</template>


<script>
export default {
  props: {
    modalTracking: {
      type: Boolean,
    },
    productId: {
      type: [Number, String],
    },
  },
  data: function () {
    return {
      mutableIfModalProduct: this.modalTracking,
      arrayColumns: [
        {
            key: "status",
            label: "Status",
            class: "text-left",
            sortable: false,
        },
        {
            key: "previous_value",
            label: "Previous Value",
            class: "text-left",
            sortable: false,
        },
        {
            key: "new_value",
            label: "New Value",
            class: "text-left",
            sortable: false,
        },
        {
            key: "created_by",
            label: "Created By",
            class: "text-left",
            sortable: false,
        },
      ],
    };
  },
  methods: {
    statusColor: function (status) {
      return status == "INPUT"
        ? "color: #00CC00"
        : "color: #FF0000";
    },
    closeModal: function () {
      this.$emit("close", false);
    },
    myProvider: async function (ctx) {
      try {
        const data = await amgApi.post(`${ctx.apiUrl}`, {
          productId: this.productId,
        });
        const items = data.data;
        return items || [];
      } catch (error) {
        console.error(error);
        return [];
      }
    },
  },
};
</script>