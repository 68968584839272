<template>
  <div>
    <b-modal
      modal
      v-model="mutableIfModalStockProduct"
      title="STOCK PRODUCT"
      @ok.prevent="updatedStockProduct"
      ok-title="SAVE"
      @hidden="closeModal"
    >
      <div>
        <ValidationObserver ref="form">
          <b-row>
            <b-col sm="6">
              <b-form-group
                id="input-group-2"
                label="PRODUCT:"
                label-for="input-2"
              >
                <b-form-input
                  v-model="product"
                  class="input-background-white"
                  disabled
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col sm="6">
              <b-form-group
                id="input-group-2"
                label="CURRENT EXISTENCE:"
                label-for="input-2"
              >
                <b-form-input
                  v-model="current_stock"
                  class="input-background-white"
                  disabled
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="4" />

            <b-col sm="4">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-form-group id="input-group-2" label-for="input-2">
                  {{ status == 1 ? "INPUT" : "OUTPUT" }}
                  <b-form-input
                    type="number"
                    v-model="stock"
                    placeholder="..."
                    :class="{ 'border border-danger': errors[0] || errorStock }"
                  ></b-form-input>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col sm="4" />
          </b-row>
        </ValidationObserver>
      </div>
    </b-modal>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import ProductInventoryService from "../services/product_inventory.service";

export default {
  props: {
    modalStock: {
      type: Boolean,
    },
    productId: {
      type: [Number, String],
    },
    status: {
      type: [Number, String],
    },
    product: {
      type: [Number, String],
    },
    current_stock: {
      type: [Number, String],
    },
  },
  data: function () {
    return {
      mutableIfModalStockProduct: this.modalStock,
      stock: "",
      errorStock: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
  },
  methods: {
    statusColor: function (status) {
      return status == "INPUT" ? "color: #00CC00" : "color: #FF0000";
    },
    closeModal: function () {
      this.$emit("close", false);
    },
    async updatedStockProduct() {
      this.errorStock =
        this.status == 2
          ? this.stock <= this.current_stock && this.stock != 0
            ? false
            : true
          : this.stock == 0 ? true : false;
      let success = await this.$refs.form.validate();
      if (!success || this.errorStock) {
        this.showWarningSwal(
          "Important",
          "Please enter a valid output/input value"
        );
      } else {
        this.errorStock = false;
        let result = await this.showConfirmSwal();
        if (result.value) {
          this.addPreloader();
          await ProductInventoryService.updatedStockProduct({
            userId: this.currentUser.user_id,
            productId: this.productId,
            currentStock: this.current_stock,
            stock: this.stock,
            status: this.status,
          });
          this.showToast();
          this.stock = "";
          this.removePreloader();
          this.closeModal();
        }
      }
    },
  },
};
</script>