import { amgApi } from '@/service/axios'

class ProductInventoryService {
    //Product Inventory Main
    async saveProductInventory(params) {
        const data = await amgApi.post("/logistics/products-inventory/save-product-inventory", params)
        return data
    }

    async getCatergoriesProduct(params) {
        const data = await amgApi.post("/logistics/products-inventory/get-catergories-product", params)
        return data
    }

    async saveCategoryProduct(params) {
        const data = await amgApi.post("/logistics/products-inventory/save-category-product", params)
        return data
    }

    async updatedStockProduct(params) {
        const data = await amgApi.post("/logistics/products-inventory/updated-stock-product", params)
        return data
    }

    async deleteProduct(params) {
        const data = await amgApi.post("/logistics/products-inventory/delete-product", params)
        return data
    }
    async getProductActions(params) {
        const data = await amgApi.post("/logistics/products-inventory/get-product-actions", params)
        return data
    }
    async getProductToEdit(params) {
        const data = await amgApi.post("/logistics/products-inventory/get-product-to-edit", params)
        return data
    }
    async editImageProduct(params){
        const data = await amgApi.post("/logistics/products-inventory/edit-image-product", params);
        return data;
    }
    async getSelectCategory(params) {
        const data = await amgApi.get("/logistics/products-inventory/get-all-product-category", params);
        return data;
    }
}

export default new ProductInventoryService()