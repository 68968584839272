<template>
  <div>
    <b-modal
      :title="productId ? 'EDIT PRODUCT' : 'REGISTER NEW PRODUCT'"
      :ok-title="'SAVE'"
      v-model="onControl"
      size="lg"
      @hidden="closeModal"
    >
      <ValidationObserver ref="form">
        <div>
          <div class="mt-3">
            <b-row>
              <b-col sm="8">
                <b-row>
                  <!-- CODE -->
                  <b-col sm="6">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <b-form-group label="CODE:" label-for="input-2">
                        <b-form-input
                          v-model="code"
                          placeholder="Code"
                          class="input-background-white"
                          :class="{ 'border border-danger': errors[0] }"
                          :disabled="productId ? true : false"
                        ></b-form-input>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <!-- CATEGORY -->
                  <b-col sm="6">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <feather-icon
                        icon="PlusIcon"
                        size="20"
                        class="cursor-pointer"
                        style="float: right"
                        title="NEW CATEGORY"
                        @click="openModalAddCategory()"
                      />
                      <b-form-group
                        id="input-group-3"
                        label="CATEGORY:"
                        label-for="input-3"
                      >
                        <b-form-select
                          v-model="category"
                          :options="optionsCategory"
                          value-field="id"
                          text-field="name"
                          class="select-icon-none"
                          :class="{ 'border border-danger': errors[0] }"
                        >
                          <template #first>
                            <b-form-select-option :value="null" disabled
                              >Please select an option</b-form-select-option
                            >
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <!-- NAME PRODUCT -->
                  <b-col sm="12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <b-form-group label="PRODUCT:" label-for="input-2">
                        <b-form-input
                          v-model="nameProduct"
                          placeholder="Product"
                          class="input-background-white"
                          :class="{ 'border border-danger': errors[0] }"
                        ></b-form-input>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <!-- Currency -->
                  <b-col sm="6">
                    <b-form-group label="Currency:" label-for="input-2">
                      <div>
                        <b-form-group v-slot="{ ariaDescribedby }">
                          <div
                            style="
                              display: flex;
                              justify-content: center;
                              margin-top: 10px;
                            "
                          >
                            <b-form-radio
                              v-model="currency"
                              :aria-describedby="ariaDescribedby"
                              name="some-radios"
                              value="1"
                              class="mr-3"
                              >$</b-form-radio
                            >
                            <b-form-radio
                              v-model="currency"
                              :aria-describedby="ariaDescribedby"
                              name="some-radios"
                              value="2"
                              >S/</b-form-radio
                            >
                          </div>
                        </b-form-group>
                      </div>
                    </b-form-group>
                  </b-col>

                  <!-- COST -->
                  <b-col sm="6">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <b-form-group label="COST:" label-for="input-2">
                        <money
                          v-bind="{
                            decimal: '.',
                            thousands: ',',
                            prefix: currency == 1 ? '$ ' : 'S/ ',
                            precision: 2,
                            masked: false,
                          }"
                          v-model="cost"
                          class="form-control"
                          :class="{
                            'border border-danger': errors[0] && vmoneyValidate,
                          }"
                        ></money>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <!-- STOCK -->
                  <b-col sm="6">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <b-form-group label="STOCK:" label-for="input-2">
                        <b-form-input
                          v-model="stock"
                          type="number"
                          placeholder="Stock"
                          class="input-background-white"
                          :class="{ 'border border-danger': errors[0] }"
                        ></b-form-input>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <!-- PURCHASE DATE -->
                  <b-col sm="6">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <b-form-group
                        id="input-group-3"
                        label="PURCHASE DATE:"
                        label-for="input-3"
                      >
                        <b-form-datepicker
                          v-model="dateRegister"
                          class="mb-2"
                          :class="{ 'border border-danger': errors[0] }"
                        ></b-form-datepicker>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <!-- DESCRIPTION -->
                  <b-col sm="12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <b-form-group label="DESCRIPTION:" label-for="input-2">
                        <b-form-textarea
                          v-model="description"
                          placeholder="Enter something..."
                          rows="3"
                          max-rows="6"
                          :class="{ 'border border-danger': errors[0] }"
                        ></b-form-textarea>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
              </b-col>

              <!-- INPUT_FILE FIN -->
              <b-col sm="4">
                <div
                  class="form-group"
                  style="
                    border: 5px solid black;
                    border-radius: 15px;
                    padding: 10px;
                  "
                >
                  <div class="image-upload">
                    <input
                      type="file"
                      id="file_input_1"
                      @change="getImage"
                      hidden
                      :disabled="!viewEdit && productId ? true : false"
                    />
                    <div
                      class="form-group"
                      :style="
                        viewEdit || !productId
                          ? 'cursor: pointer'
                          : 'cursor: not-allowed'
                      "
                    >
                      <figure @click="inputImage()">
                        <img width="100%" height="80%" :src="image" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div
                  class="form-group"
                  style="text-align: center"
                  v-if="productId"
                >
                  <div v-if="!viewEdit">
                    <feather-icon
                      icon="EditIcon"
                      size="25"
                      class="cursor-pointer mr-1"
                      style="color: green"
                      @click="clickEdit(1)"
                    />
                    <feather-icon
                      icon="Trash2Icon"
                      size="25"
                      class="cursor-pointer mr-1"
                      style="color: red"
                      @click="saveImage(1)"
                    />
                  </div>
                  <div v-else>
                    <feather-icon
                      icon="SaveIcon"
                      size="25"
                      class="cursor-pointer mr-1"
                      style="color: blue"
                      @click="saveImage(2)"
                    />
                    <feather-icon
                      icon="XCircleIcon"
                      size="25"
                      class="cursor-pointer mr-1"
                      style="color: red"
                      @click="clickEdit(2)"
                    />
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </ValidationObserver>
      <template #modal-footer>
        <b-button variant="primary" @click="saveProduct">SAVE</b-button>
      </template>
    </b-modal>

    <!-- MODAL REGISTER CATEGORY -->
    <b-modal
      modal
      v-model="ModalAddCategory"
      title="REGISTER CATEGORY"
      @ok.prevent="registerCategory"
      ok-title="SAVE"
    >
      <div>
        <ValidationObserver ref="formCategory">
          <b-row>
            <b-col sm="12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-form-group label="CATEGORY:" label-for="input-2">
                  <b-form-input
                    v-model="newCategory"
                    placeholder="..."
                    :class="{ 'border border-danger': errors[0] }"
                  ></b-form-input>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
        </ValidationObserver>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProductInventoryService from "../services/product_inventory.service";
export default {
  props: {
    productId: {
      type: Number,
    },
  },
  components: {
    //
  },
  data() {
    return {
      onControl: false,
      category: null,
      optionsCategory: [],
      dateRegister: null,
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        precision: 2,
        masked: false,
      },
      vmoneyValidate: false,
      ModalAddCategory: false,
      newCategory: "",
      product: {
        images: "",
      },
      urlImage: "/assets/images/product.png",
      viewImage: null,
      code: "",
      nameProduct: "",
      description: "",
      currency: 1,
      stock: null,
      cost: 0,
      imageUrl: "",
      viewEdit: false,
    };
  },
  created() {
    this.onControl = true;
    this.getSelectCategory();
    if (this.productId) {
      this.getProductToEdit();
    }
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    image() {
      if (!this.viewImage) {
        return this.urlImage;
      } else {
        return this.viewImage;
      }
    },
  },
  methods: {
    async saveImage(value) {
      let result = await this.showConfirmSwal();
      if (result.value) {
        this.addPreloader();
        this.viewEdit = false;

        let formData = new FormData();
        formData.append("productId", this.productId);
        formData.append("images", this.product.images);
        formData.append("value", value);
        await ProductInventoryService.editImageProduct(formData);

        if (value == 1) {
          this.viewImage = this.urlImage;
          this.saveUrlImage = this.viewImage;
        } else {
          this.saveUrlImage = this.viewImage;
        }
        this.showToast();
        this.removePreloader();
        this.$emit("reloadGrilla");
      }
    },
    clickEdit(value) {
      if (value == 1) {
        this.viewEdit = true;
      } else {
        this.viewEdit = false;
        this.viewImage = this.saveUrlImage;
      }
    },
    async saveProduct() {
      this.vmoneyValidate = true;
      let success = await this.$refs.form.validate();
      if (!success) {
        return;
      } else {
        try {
          this.vmoneyValidate = false;
          let result = await this.showConfirmSwal();
          if (result.value) {
            this.addPreloader();
            let formData = new FormData();
            formData.append("userId", this.currentUser.user_id);
            formData.append("code", this.code);
            formData.append("category", this.category);
            formData.append("nameProduct", this.nameProduct);
            formData.append("currency", this.currency);
            formData.append("cost", this.cost);
            formData.append("stock", this.stock);
            formData.append("dateRegister", this.dateRegister);
            formData.append("images", this.product.images);
            formData.append("description", this.description);
            formData.append("product_id", this.productId);
            formData.append("saveUrlImage", this.saveUrlImage);
            formData.append("imageUrl", this.imageUrl);
            await ProductInventoryService.saveProductInventory(formData);
            this.showToast();
            this.newBrand = "";
            this.removePreloader();
            this.closeModal();
          }
        } catch (error) {
          this.showErrorSwal(error);
        }
      }
    },
    async getProductToEdit() {
      try {
        const response = await ProductInventoryService.getProductToEdit({
          product_id: this.productId,
        });
        this.code = response.data[0].code;
        this.category = response.data[0].category_id;
        this.nameProduct = response.data[0].product;
        this.currency = response.data[0].currency;
        this.stock = response.data[0].current_stock;
        this.cost = response.data[0].cost;
        this.dateRegister = response.data[0].date_of_purchase;
        this.description = response.data[0].description;
        this.viewImage = response.data[0].url_image;
        this.saveUrlImage = response.data[0].url_image;
        this.imageUrl = response.data[0].imageUrl;
      } catch (error) {
        console.log(error);
      }
    },
    getImage(e) {
      let file = e.target.files[0];
      this.product.images = file;
      this.uploadImage(file);
    },

    uploadImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.viewImage = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    inputImage() {
      document.getElementById("file_input_1").click();
    },

    openModalAddCategory() {
      this.ModalAddCategory = true;
    },
    closeModalAddCategory() {
      this.ModalAddCategory = false;
    },
    async registerCategory() {
      let success = await this.$refs.formCategory.validate();
      if (!success) {
        return;
      } else {
        let result = await this.showConfirmSwal();
        if (result.value) {
          this.addPreloader();
          await ProductInventoryService.saveCategoryProduct({
            userId: this.currentUser.user_id,
            newCategory: this.newCategory,
          });
          this.showToast();
          this.newCategory = "";
          this.removePreloader();
          this.closeModalAddCategory();
          this.getSelectCategory();
        }
      }
    },
    closeModal() {
      this.$emit("close");
    },
    async getSelectCategory() {
      try {
        const response = await ProductInventoryService.getCatergoriesProduct(
          {}
        );
        if (response.status == 200) {
          this.optionsCategory = response.data;
        }
      } catch (error) {
        console.error(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong!"
        );
      }
    },
  },
};
</script>

<style scoped>
.w-82 {
  width: 82.64px;
}
.input-background-white {
  background: white;
}
</style>

