<template>
  <div class="border-top-info border-3 box-shadow-3 rounded-bottom">
    <filter-slot
      :filter="filter"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :send-multiple-sms="false"
      @reload="$refs['refProductsInventory'].refresh()"
    >
      <!-- Table -->
      <b-table
        small
        slot="table"
        no-provider-filtering
        :api-url="'/logistics/products-inventory/get-products-inventory'"
        ref="refProductsInventory"
        :items="myProvider"
        :fields="arrayColumns"
        primary-key="id"
        table-class="text-nowrap"
        responsive="sm"
        show-empty
        sticky-header="70vh"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :current-page="paginate.currentPage"
        :per-page="paginate.perPage"
        :busy.sync="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Loading ...</strong>
          </div>
        </template>
        <!-- Column # -->
        <template #cell(selected)="data">
          <div>
            <b-form-checkbox
              v-model="equipments"
              :value="{ id: data.item.id, category_id: data.item.category_id }"
              @change="selectAddOn(data.item)"
            ></b-form-checkbox>
          </div>
        </template>
        <!-- Column IMAGE -->
        <template #cell(url_image)="data">
          <div class="image-upload">
            <input type="file" id="file_input" hidden />
            <div class="form-group">
              <figure>
                <img
                  v-if="data.item.url_image"
                  width="80"
                  height="80"
                  :src="data.item.url_image"
                />
                <img
                  v-else
                  width="80"
                  height="80"
                  src="/assets/images/product.png"
                />
              </figure>
            </div>
          </div>
        </template>

        <!-- Column PRICE -->
        <template #cell(cost)="data">
          {{ data.item.currency }} {{ data.item.cost }}
        </template>

        <!-- Column Description -->
        <template #cell(description)="data">
          <div style="white-space: normal" v-html="data.item.description"></div>
        </template>

        <!-- Column TRACKING -->
        <template #cell(tracking)="data">
          <div>
            <b-button
              size="sm"
              variant="light"
              @click="openModalTrackingProduct(data.item.id)"
              >TRACKING</b-button
            >
          </div>
        </template>

        <!-- Column CREATED BY -->
        <template #cell(created_at)="data">
          <div
            class="d-flex flex-column justify-content-start align-items-start"
          >
            <span>{{ data.item.created_by }}</span>
            <div>{{ data.item.created_at | myGlobalDay }}</div>
          </div>
        </template>

        <!-- Columns ACTIONS -->
        <template #cell(actions)="data">
          <feather-icon
            icon="Edit2Icon"
            class="cursor-pointer text-warning mr-2"
            size="16"
            @click="editProduct(data.item.id)"
          />
          <feather-icon
            icon="TrashIcon"
            class="cursor-pointer text-danger"
            size="16"
            @click="deleteProduct(data.item.id)"
          />
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click="
                openModalStock(
                  data.item.id,
                  data.item.product,
                  data.item.current_stock,
                  1
                )
              "
            >
              <feather-icon icon="PlusCircleIcon" size="20" />
              <span class="align-middle ml-50">INPUT</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="
                openModalStock(
                  data.item.id,
                  data.item.product,
                  data.item.current_stock,
                  2
                )
              "
            >
              <feather-icon icon="MinusCircleIcon" size="20" />
              <span class="align-middle ml-50">OUTPUT</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </filter-slot>

    <!-- Modal Tracking Equipment -->
    <modal-tracking-product
      v-if="modalTracking"
      :modalTracking="modalTracking"
      :productId="productId"
      @close="closeModalTrackingEquipment"
    ></modal-tracking-product>

    <!-- Modal Tracking Equipment -->
    <modal-stock-product
      v-if="modalStock"
      :modalStock="modalStock"
      :productId="productId"
      :product="product"
      :status="status"
      :current_stock="current_stock"
      @close="closeModalStock"
    ></modal-stock-product>

    <register-product
      v-if="modalRegister"
      :product-id="selectedProductId"
      @close="closeModalEdit"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import vSelect from "vue-select";

import ProductInventoryService from "../services/product_inventory.service";
// Import Filter
import FilterSlot from "@/views/crm/views/sales-made/components/slots/FilterSlot.vue";

import fields from "../data/fields.inventorygrid.data";
import filters from "../data/filter.inventorygrid.data";

// MODAL
import ModalTrackingProduct from "../modals/TrackingProduct.vue";
import ModalStockProduct from "../modals/StockProduct.vue";
import RegisterProduct from "../modals/RegisterProduct.vue";
export default {
  props: {
    //
  },
  components: {
    vSelect,
    FilterSlot,
    "modal-tracking-product": ModalTrackingProduct,
    "modal-stock-product": ModalStockProduct,
    "register-product": RegisterProduct,
  },
  data: function () {
    return {
      sortBy: "created_at",
      sortDesc: true,
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Product...",
        model: "",
      },
      arrayColumns: fields,
      assetsImg: process.env.VUE_APP_BASE_URL_ASSETS,
      startPage: null,
      toPage: null,
      modalTracking: false,
      sortBy: "created_at",
      filter: filters,
      isBusy: false,
      productId: "",
      modalStock: false,
      status: "",
      product: "",
      current_stock: "",
      modalRegister: false,
      selectedProductId: null,
    };
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
  },
  mounted() {
    //
  },
  created() {
    this.getSelectCategory();
  },
  methods: {
    async myProvider(ctx) {
      try {
        const data = await amgApi.post(
          `${ctx.apiUrl}?page=${ctx.currentPage}`,
          {
            perpage: ctx.perPage,
            name_text: this.filterPrincipal.model,
            from: this.filter[0].model,
            to: this.filter[1].model,
            statusProduct: 1,
            order: ctx.sortBy == "" ? "created_at" : ctx.sortBy,
            orderby: ctx.sortDesc == 1 ? "desc" : "asc",
            category: this.filter[2].model,
          }
        );
        const items = data.data.data;
        this.startPage = data.data.from;
        this.currentPage = data.data.current_page;
        this.perPage = data.data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.data.last_page;
        this.totalRows = data.data.total;
        this.toPage = data.data.to;
        return items || [];
      } catch (error) {
        this.showErrorSwal(error);
        return [];
      }
    },
    editProduct(id) {
      this.modalRegister = true;
      this.selectedProductId = id;
    },
    closeModalEdit() {
      this.modalRegister = false;
      this.$refs["refProductsInventory"].refresh();
    },
    openModalTrackingProduct(productId) {
      this.productId = productId;
      this.modalTracking = true;
    },
    closeModalTrackingEquipment() {
      this.modalTracking = false;
    },
    reloadGrilla() {
      this.$refs.refProductsInventory.refresh();
    },

    openModalStock(productId, product, current_stock, status) {
      this.status = status;
      this.productId = productId;
      this.product = product;
      this.current_stock = current_stock;
      this.modalStock = true;
    },
    closeModalStock() {
      this.modalStock = false;
      this.$refs.refProductsInventory.refresh();
    },
    async deleteProduct(productId) {
      const result = await this.showConfirmSwal();
      if (result.value) {
        try {
          await ProductInventoryService.deleteProduct({ productId });
          this.showToast();
        } catch (error) {
          this.showErrorSwal(error);
        }
      }
    },
    getSelectCategory: async function () {
      try {
        const response = await ProductInventoryService.getSelectCategory({});
        if (response.status == 200) {
          this.filter[2].options = response.data;
        }
      } catch (error) {
        console.error(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong!"
        );
      }
    },
  },
};
</script>
